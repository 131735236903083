import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { deleteState, loadState, saveState } from "../state/utils/localStorage";
import { navigate } from "gatsby";
import { getGuestCompleteInfo } from "../apis/guest";
import "tailwindcss/tailwind.css";
import { useDispatch } from "react-redux";
import { fetchGuestInfoAction } from "../state/actions/guestInfoAction";

const Callback = (props) => {
  const { handleRedirectCallback, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  useEffect(() => {
    const error_description = new URLSearchParams(window.location.search).get(
      "error_description"
    );
    const handleRedirection = async () => {
      if (error_description === "user is blocked") {
        return navigate("/zh/account-blocked");
      } else if (error_description && error_description !== "user is blocked") {
        return navigate(
          `/zh/login-failed?error_description=${error_description}`
        );
      }
      const callback = await handleRedirectCallback();
      const token = await getAccessTokenSilently();
      const guestInfo = await getGuestCompleteInfo(token);
      dispatch(fetchGuestInfoAction(token));
      saveState("auth_token", token);
      const redirect_uri = loadState(callback.appState.target);
      deleteState(callback.appState.target);
      //if email is not present
      if (!guestInfo.data.data.guest.isEmailVerify) {
        const locale = guestInfo.data.data.guest.language.toLowerCase();
        if (locale === "en") {
          return navigate(
            `/verify-account?email=${guestInfo.data.data.guest.email}`,
            {
              replace: true,
            }
          );
        } else {
          return navigate(
            `/${locale}/verify-account?email=${guestInfo.data.data.guest.email}`,
            {
              replace: true,
            }
          );
        }
      }
      //if user is not verified
      //but first and last name is present
      if (
        !guestInfo.data.data.guest.isEmailVerify &&
        guestInfo.data.data.guest.firstName &&
        guestInfo.data.data.guest.lastName
      ) {
        const locale = guestInfo.data.data.guest.language.toLowerCase();
        if (locale === "en") {
          return navigate(`/`, {
            replace: true,
          });
        } else {
          return navigate(`/${locale}`, {
            replace: true,
          });
        }
      }
      //if last or first name is not present
      if (
        guestInfo.data.data.guest.isEmailVerify &&
        !guestInfo.data.data.guest.firstName &&
        !guestInfo.data.data.guest.lastName
      ) {
        const locale = guestInfo.data.data.guest.language.toLowerCase();
        if (locale === "en") {
          return navigate(`/complete-profile`, {
            replace: true,
          });
        } else {
          return navigate(`/${locale}/complete-profile`, {
            replace: true,
          });
        }
      }
      //if no redirection is mentioned [by accident]
      if (!redirect_uri) {
        return navigate("/");
      }
      //redirect to redirect url
      return navigate(redirect_uri);
    };

    handleRedirection();
  }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "grid",
        placeItems: "center",
      }}>
      <div
        className="tw-flex tw-flex-col tw-items-center tw-justify-center"
        style={{
          gap: "1rem",
        }}>
        <div
          className="tw-flex tw-flex-col tw-items-center"
          style={{
            gap: "1rem",
          }}>
          <div className="tw-animate-pulse tw-transform">
            <img
              src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/AYANA_Rewards_logo_2023_6baf3ef4d3.png"
              className="tw-max-w-[120px]"
              alt="ayana-rewards-logo"
            />
          </div>
        </div>
        <p className="tw-text-[20px] tw-leading-[28px] tw-opacity-50 tw-animate-pulse tw-font-serif">
          正在重定向回....
        </p>
      </div>
    </div>
  );
};

export default Callback;
